.unit-title-v1 {
	h1 {
		font-family: Avenir LT Pro\ 65;
		font-size: 32px;
		color: #445283;
		font-weight: 500;
		line-height: 1.2;
		letter-spacing: 4px;
		text-transform: uppercase;
		text-align: center;
		color: #fff;
		margin: 130px 0 90px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		padding-right: 150px;
		text-align: left;

		@media (max-width: 992px) {
			margin-top: 50px;
			text-align: left;
			font-size: 1.5em;
		}
	}

	.stars {
		color: var(--color-stars);
		vertical-align: top;
		position: absolute;
		right: 0;
		top: 10px;
		font-size: 16px;
	}
}