.lage-page-v1 {
	.infotabs ul {
		float: left;
		margin: 0px;
		padding: 0px;
		width: 100%;
		list-style: none;
		border-bottom: 1px solid rgba(240, 240, 240, 1.0);
	}

	.infotabs ul li {
		display: inline;
		float: left;
		margin: 0px 50px -1px 0px;
	}

	.infotabs ul li a {
		display: block;
		padding: 0px 0px 16px 0px;
		font-size: 0.85em;
		line-height: 160%;
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		color: var(--color-grey-dark);
	}

	.infotabs ul li a:hover {
		border: 0px solid transparent;
		text-decoration: none;
		color: var(--color-secondary);
	}

	.infotabs ul li a.active {
		border-radius: 0px;
		border: 0px;
		border-bottom: 1px solid var(--color-secondary);
		color: var(--color-secondary);
	}

	.infotabs ul li a.active:hover {
		border-radius: 0px;
		border: 0px;
		border-bottom: 1px solid var(--color-secondary);
	}

	/*.infotabs ul li a img { filter:grayscale(100%);}*/
	.infotabs ul li a.active img {
		filter: none;
	}

	.infotabs .tab-content,
	.infotabs .tab-pane {
		padding: 20px 0px;
		text-align: left;
	}

	.infotabs .location-icon {
		display: inline;
		width: 14px;
		margin: -9px 8px 0px 0px;
	}

	.lage-map {
		#map {
			min-height: 600px !important;
		}
	}
}