.searchbar-v3 {
	position: absolute;
	bottom: 100px;
	width: 100%;


	@media (max-width: 576px) {
		padding: 0px 10px;
	}

	@media (max-width: 767px) {
		bottom: unset;
		top: unset;
		position: relative;
	}

	@media (max-height: 600px) {
		top: 380px;
	}

	@media all and (min-width: 768px) and (max-width: 992px) {
		padding: 0 15px;
		bottom: unset;
		top: 60vh;
	}

	.container {
		max-width: 920px;
	}

	.tab {
		color: #fff;
		background-color: var(--color-secondary);
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		padding: 6px 20px;
		opacity: 1;
		cursor: pointer;
		display: inline-block;

		@media (max-height: 600px) {
			padding: 6px 9px;
		}


		&.active {
			background-color: var(--color-primary);
			color: #fff;
		}

	}

	#search-obj-form {
		display: none;
	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		background-color: #fff;
		background-color: var(--color-white);
		border: 10px solid #eaeaea;
		flex-flow: wrap;
		border: 10px solid var(--color-grey-normal);
		border-radius: 8px;

		.searchbox-obj {
			padding-top: 0px;
		}

		.form-obj-flex {
			width: 90%;
			display: flex;
			align-items: center;
			padding: 9px 15px;

			.form-control {
				padding-left: 40px !important;
			}

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;
			}

			.fa {
				position: absolute;
				left: 10px;
				top: 11px;
			}

			.form-control {
				background-color: transparent;
				border: none;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: 1px solid #ddd;
				padding: 8px 10px 8px 30px;
				min-height: 40px !important;
				font-size: 14px;
				line-height: 20px;
				padding-right: 30px;
				border-radius: 0 !important;
			}

			.form-flex-area {
				position: relative;
				width: 100%;
			}

		}

		.form-flex {
			width: 45%;
			padding: 9px 15px;
			border-right: 1px solid #f4f4f4;


			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;
			}


			.fa {
				position: absolute;
				left: 10px;
				top: 11px;
			}

			#datepicker-trigger-flex {

				height: auto !important;
				padding-right: 30px;
			}

			.form-control {
				background-color: transparent;
				border: none;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: 1px solid #ddd;
				padding: 8px 10px 8px 30px;
				min-height: 40px !important;
				font-size: 14px;
				line-height: 20px;
				padding-right: 30px;
				border-radius: 0;
			}

			.form-flex-area {
				position: relative;
			}
		}

		.form-btn {
			width: 10%;
			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding-right: 10px;

			@media (max-width:992px) {
				width: 100%;
				padding: 0 15px;
			}

			.btn {
				width: 101%;
				height: 50px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 28px;
				margin: 15px 0;

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}

				@media (max-width:767px) {
					margin: 5px 0;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}