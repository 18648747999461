.impressum-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/impressum-banner.jpg);
	}

	h6 {
		font-family: Avenir LT Pro;
		color: #646464;
		font-size: 20px;
		line-height: 34px;
		padding-top: 20px;
		padding-bottom: 15px;
	}

	p {
		font-size: 16px;
		line-height: 25px;
	}

	hr {
		border-color: rgba(100, 100, 100, 1.0) !important;
		margin: 60px 0px;
	}

	.max-250 {
		max-width: 250px;
		width: 100%;
	}

	ul {
		list-style: disc;
		padding-left: 35px;
		font-size: 16px;
		line-height: 25px;
		font-family: Avenir LT Pro;
		color: #646464;
		margin-bottom: 25px;

		li {
			margin-bottom: 10px;
		}
	}
}