@import "mixin.css";

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}

.flex {
	@mixin flex;
}

.size100 {
	@mixin size100;
}


html,
body {
	font-family: var(--font-family-main);
	color: var(--font-color-main);
	font-size: var(--font-size-main);
	font-weight: var(--font-weight-main);

}

h1,
.h1 {
	font-family: var(--h1-font-family);
	font-weight: var(--h1-font-weight);
	font-size: var(--h1-font-size);
	color: var(--h1-font-color);
	text-transform: var(--h1-text-transform);
	text-decoration: var(--h1-text-decoration);

}

.h1-width-small {
	line-height: var(--h1-font-size);

	small {
		font-size: var(--font-size-main);
		text-transform: none;
		display: inline-block;
	}
}

h2,
.h2 {
	font-family: var(--h2-font-family);
	font-weight: var(--h2-font-weight);
	font-size: var(--h2-font-size);
	color: var(--h2-font-color);
	text-transform: var(--h2-text-transform);
	text-decoration: var(--h2-text-decoration);
}

h3,
.h3 {
	font-family: var(--h3-font-family);
	font-weight: var(--h3-font-weight);
	font-size: var(--h3-font-size);
	color: var(--h3-font-color);
	text-transform: var(--h3-text-transform);
	text-decoration: var(--h3-text-decoration);
}

h4,
.h4 {
	font-family: var(--h4-font-family);
	font-weight: var(--h4-font-weight);
	font-size: var(--h4-font-size);
	color: var(--h4-font-color);
	text-transform: var(--h4-text-transform);
	text-decoration: var(--h4-text-decoration);
}

h5,
.h5 {
	font-family: var(--h5-font-family);
	font-weight: var(--h5-font-weight);
	font-size: var(--h5-font-size);
	color: var(--h5-font-color);
	text-transform: var(--h5-text-transform);
	text-decoration: var(--h5-text-decoration);
}

.heading1 {
	display: inline-block;
	padding-right: 0px;
	padding-left: 0px;
	border-bottom: 0px solid var(--heading1-border-color);
	margin-bottom: var(--heading1-margin-bottom);
	text-transform: var(--heading1-text-transform);
	padding-bottom: 0;
	margin-bottom: 0;
	font-size: 2em;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	color: #445283;
	margin: 0px 0px 20px 0px;


	@media (max-width: 1200px) {
		font-size: 1.8rem !important;
	}

	@media (max-width: 767px) {
		font-size: 1.5rem !important;
	}
}

a {
	color: var(--link-font-color);
	text-decoration: var(--link-text-decoration);
	pointer-events: unset !important;

	&:hover {
		text-decoration: var(--link-text-decoration-hover);
		color: var(--link-font-color-hover);
	}
}

p {
	font-family: "Avenir LT Pro";
	color: rgba(100, 100, 100, 1.0);
	font-size: 20px;
	line-height: 34px;

	@media (max-width: 1200px) {
		font-size: 18px;
		line-height: 30px;
	}

	@media (max-width: 767px) {
		font-size: 0.9em !important;
		line-height: 160%;
	}

}

ul {
	padding: 0;
	margin: 0 0 15px;
	list-style: none;
}

.form-control:focus {
	box-shadow: none;
}

.btn:focus,
.btn:active {
	box-shadow: none;
}

/*--- background-colors---*/

.bg-primary {
	/* background-color: var(--color-primary); */
	background-color: transparent;
}

.bg-primary-light {
	background-color: var(--color-primary-light);
}

.bg-secondary {
	background-color: var(--color-secondary);
}

.bg-secondary-light {
	background-color: var(--color-secondary-light);
}

.bg-white {
	background-color: var(--color-white);
}

.bg-grey-light {
	background-color: var(--color-grey-light);
}

.bg-grey-normal {
	background-color: var(--color-grey-normal);
}

.bg-grey-dark {
	background-color: var(--color-grey-dark);
}

.bg-black {
	background-color: var(--color-black);
}

/*--- font-colors---*/

.color-primary {
	color: var(--color-primary) !important;
}

.color-primary-light {
	color: var(--color-primary-light) !important;
}

.color-secondary {
	color: var(--color-secondary) !important;
}

.color-secondary-light {
	color: var(--color-secondary-light) !important;
}

.color-white {
	color: var(--color-white) !important;
}

.color-grey-light {
	color: var(--color-grey-light) !important;
}

.color-grey-normal {
	color: var(--color-grey-normal) !important;
}

.color-grey-dark {
	color: var(--color-grey-dark) !important;
}



.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

/*--- Datepicker ---*/

.datepicker-trigger {

	.asd__day--disabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}


	.asd__day--disabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled {
		background-color: var(--cal-available) !important;
		color: var(--cal-text-color) !important;

		&.asd__day--disabled {
			background-color: var(--cal-disabled) !important;
			opacity: 0.8 !important;
			text-decoration: underline;

			&.asd__day--selected {
				background-color: var(--cal-selected) !important;
			}

			&.asd__day--in-range {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			}

			/*	&.asd__day--hovered {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			} */
		}


	}

	.asd__day--enabled:not(.asd__day--disabled) {
		&:hover {
			background: var(--cal-selected) !important;
			opacity: 1 !important;
			color: var(--cal-text-color-hover) !important;
		}
	}

	.asd__day--selected {
		background: var(--cal-selected) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;

		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				left: 0px;
			}
		}

	}
}

/*--- Buttons ---*/

.btn {
	border-radius: var(--btn-border-radius);
	font-size: var(--btn-font-size);
	/*	padding: 8px 16px; */
	text-transform: var(--btn-text-transform);

	&.btn-primary,
	&.btn-go {
		/* background-color: var(--btn-primary-bg-color); */
		background: transparent;
		color: var(--btn-primary-font-color);
		border-color: var(--btn-primary-border-color);
		border-radius: 0;
		text-transform: uppercase;
		padding-left: 25px;
		padding-right: 25px;
		letter-spacing: 2px;
		font-size: 14px;
		padding: 9px 30px 9px 30px;

		&:hover {
			background-color: var(--btn-primary-bg-color-hover);
			border-color: var(--btn-primary-border-color-hover);
			color: var(--btn-primary-font-color-hover);
		}
	}

	&.btn-secondary {
		background: transparent;
		color: var(--btn-primary-font-color);
		border-color: var(--btn-primary-border-color);
		border-radius: 0;
		text-transform: uppercase;
		padding-left: 25px;
		padding-right: 25px;
		letter-spacing: 0.2em;
		font-size: 0.8em;
		padding: 9px 30px 9px 30px;

		&:hover {
			background-color: var(--btn-primary-bg-color-hover);
			border-color: var(--btn-primary-border-color-hover);
			color: var(--btn-primary-font-color-hover);
		}
	}

	&.btn-default {
		background-color: var(--btn-default-bg-color);
		color: var(--btn-default-font-color);
		border-color: var(--btn-default-border-color);

		&:hover {
			background-color: var(--btn-default-bg-color-hover);
			border-color: var(--btn-default-border-color-hover);
			color: var(--btn-default-font-color-hover);
		}
	}

	&.btn-add {
		background-color: var(--btn-add-bg-color);
		color: var(--btn-add-font-color);
		border-color: var(--btn-add-border-color);

		&:hover {
			background-color: var(--btn-add-bg-color-hover);
			border-color: var(--btn-add-border-color-hover);
			color: var(--btn-add-font-color-hover);
		}
	}

	&.btn-remove {
		background-color: var(--btn-remove-bg-color);
		color: var(--btn-remove-font-color);
		border-color: var(--btn-remove-border-color);

		&:hover {
			background-color: var(--btn-remove-bg-color-hover);
			border-color: var(--btn-remove-border-color-hover);
			color: var(--btn-remove-font-color-hover);
		}
	}

	&.btn-bc {
		background-color: var(--btn-breadcrumb-bg-color);
		color: var(--btn-breadcrumb-font-color);
		border-color: var(--btn-breadcrumb-border-color);

		&:hover {
			background-color: var(--btn-breadcrumb-bg-color-hover);
			border-color: var(--btn-breadcrumb-border-color-hover);
			color: var(--btn-breadcrumb-font-color-hover);
		}
	}

	&.btn-sm {
		font-size: var(--font-size-sm);
		padding: 6px 12px;
	}

	&.btn-lg {
		height: 46px;
		font-size: var(--font-size-lg);
		padding: 10px 16px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}

	&.btn-arrow {
		min-width: 220px;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width:992px) {
			min-width: 160px;
		}

		.fa {
			opacity: .4;
			margin-left: 15px;
		}
	}
}

.breadcrumb {
	background-color: #fff !important;
	padding-left: 0px;
	padding-bottom: 0;

}

.btn-breadcrumb .btn:not(:last-child):after {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid white;
	position: absolute;
	top: 50%;
	margin-top: -17px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn:not(:last-child):before {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid rgb(173, 173, 173);
	position: absolute;
	top: 50%;
	margin-top: -17px;
	margin-left: 1px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn {
	padding: 6px 12px 6px 24px;

	&.disabled {
		opacity: 0.6;
		color: #000;

	}
}

.btn-breadcrumb .btn:first-child {
	padding: 6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
	padding: 6px 18px 6px 24px;
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}



.breadcrumb li a:hover:after {
	border-left-color: #ffc107 !important;
}

.next-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;

		@media (max-width:576px) {
			top: 20%;
		}




		button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border-radius: 50%;
			box-shadow: 0 0 8px rgba(0, 0, 0, .21);
			background-color: var(--color-secondary) !important;
			position: absolute;
			left: 0;

			@media (max-width:991px) and (min-width:600px) {
				left: -30px;
			}

			@media (min-width:1060px) {
				left: -50px;
			}


			span {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;

				&:before {
					color: var(--color-white);
					font-family: 'Font Awesome 5 Pro';
					position: relative;
					left: 2px;
					font-size: 16px;
					top: 1px;
					font-weight: 300;
				}
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: initial;

				@media (max-width:991px) and (min-width:600px) {
					right: -30px;
				}

				@media (min-width:1060px) {
					right: -50px;
				}

				/*	@media (max-width:992px) {
					right: -6px;
				} */

				span {

					&:before {
						content: "\f054";
						left: 3px;
					}
				}
			}

			&.owl-prev {
				span {

					&:before {
						content: "\f053";

					}
				}
			}
		}
	}
}

.flex-align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.single-unit {
	margin: 0 auto 10px;
	max-width: 500px;
	transition: all 0.25s ease 0s;
}

.owl-carousel.off {
	display: block;
}

.payment-view {
	margin-top: var(--page-margin-top);
	padding-top: 30px;
	padding-bottom: 60px;
}

.inner-banner {
	background-image: url(RESOURCE/img/demo-banner1.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 420px;
	position: relative;

	@media (max-width:992px) {
		height: 320px;
	}


	.page-title {
		position: absolute;
		bottom: 20px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;
		text-align: right;

		@media (max-width:992px) {
			bottom: 30px;
		}


		.pag-title {
			display: inline-block;
			font-size: 36px;
			margin-bottom: 0;
			color: var(--color-white);
			text-transform: uppercase;
			padding: 0 10px;
			border-bottom: 1px solid var(--color-white);
			text-shadow: rgb(0 0 0 / 50%) 2px 2px 10px;

			@media (max-width:992px) {
				font-size: 24px;
			}

			span {
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
			}
		}


	}
}

.img-responsive {
	max-width: 100%;
}

.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.googleMapsHint {
	color: var(--googlemaps-hint-text-color);
	font-size: var(--googlemaps-hint-font-size);
	width: 100%;
}

.no-google-map {
	width: 100%;
	height: 150px;
	position: relative;
}

.box {
	position: absolute;
	z-index: 999 !important;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;
		max-width: 280px;
		justify-content: center;
		width: 100%;
		margin: 30px auto 0 !important;
	}

	span {
		font-size: 15px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
			color: #fff;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;

		@media (max-width: 767px) {
			margin: 0 20px 10px 10px;
			max-width: 160px;
			font-size: 15px;
		}

		@media all and (min-width: 400px) and (max-width: 480px) {
			max-width: 180px;
		}
	}
}

.asd__wrapper {
	@media (max-width: 480px) {
		margin: 0 auto;
	}
}

/*@media (min-width:1200px) {
	.container {
		max-width: 1230px;
	}
}*/

@media (min-width:1500px) {
	.container {
		max-width: 1370px;
	}

	.container-xl {
		max-width: 1370px;
	}
}

.pswp__caption__center {
	text-align: center !important;
}

.hide-mob {
	display: block;

	@media (max-width:992px) {
		display: none;
	}
}

.show-mob {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.fal {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;

}

.covid-section {
	margin: 0;
	position: absolute;
	top: 180px;
	right: 40px;
	z-index: 2;
	transform: rotate(10deg);


	@media (max-width: 1140px) {
		right: 20px;
		top: 120px;
		bottom: inherit;
	}

	@media (max-width: 768px) {
		right: 20px;
		top: 150px;
		bottom: inherit;
	}

	@media (max-width: 560px) {
		right: 10px;
		top: 130px;
		bottom: inherit;
	}


	.btn {
		width: 160px;
		height: 160px;
		border-radius: 100px;
		font-size: 18px;
		padding: 15px;
		background: rgba(168, 131, 81, .8);
		color: #fff;

		@media (max-width:1200px) {
			width: 130px;
			height: 130px;
			white-space: unset;
			font-size: 16px;
		}

		@media (max-width: 992px) {
			width: 110px;
			height: 110px;
			font-size: 13px;

		}

		@media (max-width: 767px) {
			width: 100px;
			height: 100px;
			font-size: 11px;

		}
	}


}

.modal-dialog {
	max-width: 800px;
	margin: 30px auto;
	width: 100%;
}

.welcome-section {
	padding: 80px 0px 80px 0px;

	@media (max-width: 767px) {
		padding: 60px 0;
	}
}

.teaser_image {
	&.top {
		img {
			object-position: top;
		}
	}

	&.top-11 {
		img {

			@media (min-width: 992px) {
				object-position: 0 -40px;
			}

			@media (min-width: 1200px) {
				object-position: 0 -90px;
			}

			@media (min-width: 1400px) {
				object-position: 0 -140px;
			}

		}
	}

	img {
		width: 100%;
		height: 420px;
		object-fit: cover;
		object-position: center;

		@media (max-width: 992px) {
			height: 380px;
		}
	}
}

.find-your-home {
	padding: 80px 0px 60px 0px !important;

	@media (max-width: 992px) {
		padding: 130px 0 60px !important
	}

	@media (max-width: 767px) {
		padding: 120px 0px 50px 0px !important;
	}

	.home-box {
		position: relative;
		overflow: hidden;

		&:hover {
			img {
				transform: scale(1.05);
			}
		}

		.card-body {
			padding: 1.25rem 0 0 0;
		}

		img {
			width: 100%;
			transition: all 0.4s ease-in-out;
		}

		.btn-primary {
			margin-top: 30px;

			@media all and (min-width: 768px) and (max-width: 991px) {
				padding-left: 15px;
				padding-right: 15px;
				margin-top: 0;
			}

			@media all and (min-width: 992px) and (max-width: 1200px) {
				margin-top: 0;
			}
		}

		h5 {
			font-family: Avenir LT Pro\ 65;
			color: rgba(100, 100, 100, 1.0);
			text-transform: uppercase;
			font-size: 13px;
			line-height: 20px;
			letter-spacing: 1px;

			@media all and (min-width: 768px) and (max-width: 1200px) {
				min-height: 60px;
			}
		}

		p {
			font-size: 15px;
			line-height: 26px;
			min-height: 100px;

			@media (max-width: 767px) {
				min-height: auto;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				font-size: 14px;
				line-height: 22px;
				min-height: 180px;
			}

			@media all and (min-width: 992px) and (max-width: 1200px) {
				font-size: 14px;
				line-height: 22px;
				min-height: 110px;
			}
		}
	}


}

.scroll-donw-bl {
	background: #fbfbfb;
	padding: 10px 0 50px 0;

	.arrow-down {
		position: relative;
		background: #a88351;
		display: inline-block;
		height: 40px;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 20px auto 0 auto;
		border-radius: 100%;

		&:before {
			content: "";
			border: solid #fff;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 6px;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			position: absolute;
			top: 10px;

		}
	}
}

.home-units {
	padding: 80px 0 80px 0;

	@media (max-width: 767px) {
		padding: 60px 0;
	}
}

.magic-of-nature {
	padding: 80px 0 80px 0;

	@media (max-width: 767px) {
		padding: 60px 0;
	}
}

.five-star-dream {
	padding: 80px 0 80px 0;

	@media (max-width: 767px) {
		padding: 60px 0;
	}
}

.discover-the-tast {
	padding: 80px 0 80px 0;

	@media (max-width: 767px) {
		padding: 60px 0;
	}
}

.feel-the-good {
	padding: 80px 0 80px 0;

	@media (max-width: 767px) {
		padding: 60px 0;
	}

}

.headline-blue {
	font-family: Avenir LT Pro\ 65;
	font-size: 32px;
	color: #445283;
	font-weight: 500;
	line-height: 1.2;
	margin: 0px 0px 20px 0px;
	letter-spacing: 4px;
	text-transform: uppercase;

	@media (max-width: 767px) {
		letter-spacing: 0.1em;
		font-size: 1.5rem !important;
	}
}

.static-view {
	margin-top: var(--page-margin-top);

	&.das-reetdorf {
		.inner-banner {
			background-image: url(RESOURCE/img/das-reetdorf-banner.jpg);
		}
	}

	&.nachhaltigkeit-page-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/nachhaltigkeit-banner.jpg);
		}
	}

	&.bildergalerie-page-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/das-reetdorf-banner.jpg);
		}
	}

	&.sterne-service-page-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/sterne-service-banner.jpg);
		}

		.additional-services {
			display: flex;
			flex-wrap: wrap;
			max-width: 500px;
			width: 100%;
			margin: 0 auto;

			li {
				width: 25%;
				margin-bottom: 20px;
				text-align: center;

				img {
					max-width: 100px;
					min-width: 100px;
					min-height: 100px;
				}

				span {
					text-align: center;
					display: block;
					width: 100%;
					padding: 10px 10px 0px 10px;
					font-size: 10px;
					text-transform: uppercase;
					font-family: Avenir LT Pro;
					color: rgba(100, 100, 100, 1.0);
					letter-spacing: 0.1em;
				}
			}
		}
	}

	&.press-page-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/presse-banner.jpg);
		}

		.link {
			.btn-secondary {
				padding: 9px 30px !important;
			}
		}


		.blogcontent {
			.img-thumbnail {
				padding: 0 !important;
				border: none !important;
				border-radius: 0 !important;
				margin-bottom: 30px;
			}

			h2 {
				font-family: Avenir LT Pro\ 65;
				color: #646464;
				text-transform: uppercase;
				font-size: 13px;
				line-height: 20px;
				letter-spacing: 1px;
				min-height: 35px;
			}



			p {
				display: none;
			}

			h3 {
				display: none;
			}

			p.lead {
				display: block;
				font-size: 16px;
				line-height: 27px;
				min-height: 110px;
				margin-bottom: 30px;
			}
		}
	}

	&.hunde-page-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/hunde-banner.jpg);
		}
	}

	&.lage-page-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/lage-banner.jpg);
		}
	}

	&.sport-freizeit-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/sport-freizeit-banner.jpg);
		}


	}

	.tip-boxes {
		.tip-box {
			margin-bottom: 40px;

			>img {}

			.header {
				display: none;
			}

			.box {
				border: none;
				border-radius: 0 !important;
				max-width: 500px;
				height: 91vh;
			}

			.content-body {
				padding: 30px 20px 15px 20px;

				h2 {
					font-family: Avenir LT Pro;
					font-size: 26px;
					letter-spacing: 0.1em;
					margin: 0px 0px 20px 0px;

					@media (max-width: 767px) {
						font-size: 24px;
					}
				}

				h4 {
					font-size: 18px;
					margin: 0 0 20px;
					line-height: 30px;
				}

				p {
					a {
						color: #646464;

						&:hover {
							color: var(--color-secondary);
						}
					}
				}

				h6 {
					color: rgba(100, 100, 100, 1.0);
					font-size: 13px;
					letter-spacing: 1px;
					text-transform: uppercase;
					margin: 30px 0 10px 0;
				}
			}

			.dlg.show {
				display: flex !important;
				align-items: center;
				justify-content: center;

				@media (max-width: 767px) {
					padding: 15px 30px;
				}
			}

			.body {
				padding: 0 !important;
				overflow: auto !important;
			}

			h4 {
				margin: 20px 0;
				color: rgba(100, 100, 100, 1.0);
				font-size: 17px;

				@media all and (min-width: 768px) and (max-width: 991px) {
					min-height: 45px;
				}
			}

			p {
				font-size: 14px;
				line-height: 160%;
				min-height: 70px;

				@media all and (min-width: 768px) and (max-width: 991px) {
					min-height: 115px;
				}
			}
		}
	}

	&.essen-trinken-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/essen-trinken-banner.jpg);
		}
	}

	&.kunst-kultur-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/kunst-kultur-banner.jpg);
		}
	}

	&.anfahrt-page-v1 {
		h6 {
			font-size: 14px;
			line-height: 20px;
		}

		#map {
			height: 420px !important;
			min-height: unset !important;

			@media (max-width:992px) {
				height: 320px !important;
			}
		}

		.map-box {
			background-color: rgba(0, 0, 0, .45) !important;
			position: absolute;
			bottom: 0;
			z-index: 1;
			right: 50px;
			padding: 25px 15px;
			bottom: 20px;
			width: 100%;
			max-width: 250px;


			@media (max-width:992px) {
				display: none;
			}

			h4 {
				color: #fff;
				font-size: 20px;
			}

			p {
				color: #fff;
				font-size: 16px;
				line-height: 28px;
				margin-bottom: 0;
			}
		}
	}

	.section-space {
		padding: 80px 0px 60px 0px;

		@media (max-width: 767px) {
			padding: 60px 0 40px 0;
		}
	}

	.magazine {
		iframe {
			width: 100%;
			height: 520px;

			@media (max-width: 1200px) {
				height: 410px;
			}

			@media (max-width: 767px) {
				height: 250px;
			}
		}
	}

	h5 {
		font-family: Avenir LT Pro\ 65;
		color: #646464;
		text-transform: uppercase;
		font-size: 13px;
		line-height: 20px;
		letter-spacing: 1px;
	}

	.floattext-small {
		font-size: 14px;
		line-height: 26px;
	}

	a {
		text-decoration: none !important;
	}

	.animated-img {
		img {
			max-width: 300px;
			transition: all 0.4s ease-in-out;
		}

		&:hover {
			img {
				transform: scale(1.05);
			}
		}
	}

	h3 {
		font-size: 25px;
		color: #445283;
		color: var(--color-secondary);
		margin: 0 0 30px 0;

		&:after {
			content: '\00ab';
			font-family: "Font Awesome 5 Free";
		}

		&:before {
			content: '\00bb';
			font-family: "Font Awesome 5 Free";
		}
	}

	.min-container {
		max-width: 950px;
	}
}

.light-bg {
	background: #fbfbfb !important;
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("RESOURCE/fonts/fa-light-300.eot");
	src: url("RESOURCE/fonts/fa-light-300.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-light-300.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-light-300.woff") format("woff"),
		url("RESOURCE/fonts/fa-light-300.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-light-300.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Avenir LT Pro 55 Roman';
	src: url('RESOURCE/fonts/AvenirLTPro-Heavy.eot');
	src: url('RESOURCE/fonts/AvenirLTPro-Heavy.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/AvenirLTPro-Heavy.woff2') format('woff2'),
		url('RESOURCE/fonts/AvenirLTPro-Heavy.woff') format('woff'),
		url('RESOURCE/fonts/AvenirLTPro-Heavy.ttf') format('truetype'),
		url('RESOURCE/fonts/AvenirLTPro-Heavy.svg#AvenirLTPro-Heavy') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

/* @font-face {
	font-family: 'Avenir LT Pro';
	src: url('RESOURCE/fonts/AvenirLTPro-Medium.eot');
	src: url('RESOURCE/fonts/AvenirLTPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/AvenirLTPro-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/AvenirLTPro-Medium.woff') format('woff'),
		url('RESOURCE/fonts/AvenirLTPro-Medium.ttf') format('truetype'),
		url('RESOURCE/fonts/AvenirLTPro-Medium.svg#AvenirLTPro-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
} */

@font-face {
	font-family: 'Avenir LT Pro';
	src: url('RESOURCE/fonts/AvenirLTPro-Roman.eot');
	src: url('RESOURCE/fonts/AvenirLTPro-Roman.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/AvenirLTPro-Roman.woff2') format('woff2'),
		url('RESOURCE/fonts/AvenirLTPro-Roman.woff') format('woff'),
		url('RESOURCE/fonts/AvenirLTPro-Roman.ttf') format('truetype'),
		url('RESOURCE/fonts/AvenirLTPro-Roman.svg#AvenirLTPro-Roman') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}



@font-face {
	font-family: 'Avenir LT Pro 65';
	src: url('RESOURCE/fonts/AvenirLTPro-Black.eot');
	src: url('RESOURCE/fonts/AvenirLTPro-Black.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/AvenirLTPro-Black.woff2') format('woff2'),
		url('RESOURCE/fonts/AvenirLTPro-Black.woff') format('woff'),
		url('RESOURCE/fonts/AvenirLTPro-Black.ttf') format('truetype'),
		url('RESOURCE/fonts/AvenirLTPro-Black.svg#AvenirLTPro-Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}


/*#swipebox-prev,
#swipebox-next,
#swipebox-close {
	background-image: url(RESOURCE/img/icons.png) !imortant;
	background-repeat: no-repeat;
	border: none !important;
	text-decoration: none !important;
	cursor: pointer;
	width: 50px;
	height: 50px;
	top: 0;
}*/

#cboxPrevious,
#cboxNext {
	background-image: url(RESOURCE/img/controls3.png) !important;
}

#cboxClose {
	background-image: url(RESOURCE/img/controls3.png) !important;
}

#cboxOverlay {
	background: #000;
}

.alert {
	border-radius: 0 !important;
}



#cboxContent,
.cboxIframe {
	margin: 0 auto;
}

.text-mob-center {
	@media (max-width: 767px) {
		text-align: center !important;
	}
}

.lage-frame {
	display: flex;
	flex-wrap: wrap;
	position: relative;

	@media (max-width: 1499px) {
		flex-direction: column-reverse;
	}

	iframe {
		border: none;
		width: 100%;

		@media (max-width: 1499px) {
			border: none;
			width: 100%;
		}
	}

	p {
		position: absolute;
		right: 0;
		width: 15%;
		margin-top: 50px;
		text-align: left;
		padding-left: 30px;

		@media (max-width: 1499px) {
			width: 100%;
			text-align: revert;
			margin-bottom: 0;
			position: relative;
			text-align: right;
			padding-right: 20px;
			max-width: 900px;
			width: 100%;
			margin: 0 auto;
		}

		i {
			position: absolute;
			left: 0;
			top: 5px;

			@media (max-width: 1499px) {
				bottom: -20px;
				top: unset;
				right: 30px;
				left: unset;
				transform: rotate(270deg);
			}
		}

	}
}

#goTop {
	position: fixed;
	bottom: 75px;
	right: 25px;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 99;
	display: none;

	&:hover {
		text-decoration: none;
	}

	i {
		font-family: Font Awesome\ 5 Pro;
		font-weight: 300;
		font-style: normal;
		color: #a88351;
		font-size: 25px;
	}

	@media(max-width: 1199px) {
		display: block;
	}

}

.cta-call {
	display: none;
	z-index: 2;
	position: relative;

	i {
		background: #85c704;
		height: 50px;
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		bottom: 0;
		border-radius: 50%;
		transform: rotate(95deg);
		color: #fff;
		bottom: 15px;
		right: 15px;
	}

	@media(max-width: 1199px) {
		display: block;
	}
}