.booking-v1 {

	.booking-view {
		background-color: var(--color-white);
		margin-top: var(--page-margin-top);
		border-radius: 0px;
		background: url(RESOURCE/img/search-banner.jpg) no-repeat;
		background-color: #0f0f17 !important;
		background-size: 100%;
		background-attachment: fixed;
		margin: 0px;
		padding: 140px 0px !important;

		@media (max-width: 992px) {
			padding: 95px 0 60px 0 !important;
			background-attachment: unset;
		}

		.step2 {
			color: #fff;

			h1 {
				font-family: Avenir LT Pro\ 65;
				font-size: 32px;
				color: #445283;
				font-weight: 500;
				line-height: 1.2;
				letter-spacing: 4px;
				text-transform: uppercase;
				text-align: center;
				color: #fff;
				margin: 15px 0 20px 0;
				width: 100%;

				@media (max-width: 767px) {
					font-size: 1.8rem !important;
				}
			}

			.bank-detail-box {
				line-height: 30px;
			}

			p {
				color: #fff;
				font-size: 18px !important;
				line-height: 24px !important;
			}

			h3 {
				color: #fff;
				font-size: 24px !important;
				margin: 15px 0 !important;
			}
		}

	}

	.left {
		background-color: rgba(0, 0, 0, .45);
		padding: 10px 15px 15px;

		.form-group {
			>label {
				color: #fff;
			}

			.form-control {
				border-radius: 0 !important;
			}
		}

		.step1 {
			h1 {
				font-family: Avenir LT Pro\ 65;
				font-size: 32px;
				color: #445283;
				font-weight: 500;
				line-height: 1.2;
				letter-spacing: 4px;
				text-transform: uppercase;
				text-align: center;
				color: #fff;
				width: 100%;
				margin: 20px 0 0 0 !important;

				@media (max-width: 991px) {
					margin-bottom: 20px !important;
				}

			}

			.Bitte {
				text-align: center;
				color: #fff;
			}
		}
	}


	.required::after {
		content: "*";
		vertical-align: super;
		font-size: var(--font-size-sm);
	}

	.label {
		display: inline-block;
	}

	.cart-details {
		background-color: rgba(0, 0, 0, .45);
		padding: 15px;
		color: #fff;

		@media (max-width: 991px) {
			padding: 0;
			background: transparent;
			margin-top: 30px;
		}

		a {
			&:hover {
				color: var(--color-secondary);
			}
		}

		.h4,
		h4 {
			color: #fff;
		}

		.unit-details {
			margin: 20px 0;
			color: var(--color-grey-dark);

			li {
				padding-left: 30px;
				padding-right: 15px;
				margin-top: 5px;
				position: relative;
				color: #fff;

				&:before {
					content: "\f00c";
					font-family: "Font Awesome 5 Free";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--color-secondary);
				}
			}
		}
	}



	.pd-form {
		label {
			font-weight: 500;
		}

	}

	.checks {

		.btn.btn-default {
			background: #fff;
			color: var(--btn-primary-font-color);
			border-color: #a88351;
			border-color: #fff;
			border-radius: 0;
			text-transform: uppercase;
			letter-spacing: 2px;
			font-size: 14px;
			padding: 9px 30px;

			&:hover {
				background: var(--color-secondary);
				color: #fff;
				border-color: var(--color-secondary);
			}
		}

		.checkbox label:before {
			border-radius: 0;
			background-color: transparent;
		}

		.checkbox-success input[type=checkbox]:checked+label:before {

			background: transparent;
			border-color: #fff;
			top: 1px;
		}

		.checkbox {


			label {
				vertical-align: inherit;
				display: unset;
				color: #fff;
			}
		}

		a {
			color: var(--color-secondary) !important;
		}
	}


	.flow-btn {
		margin-left: 20px;
	}



}

.amount-options-list {
	margin: 20px 0;

	.amount-option-btn {
		.amount-or {
			text-align: center;
			margin: 15px 0;
			width: 100%;
			max-width: 305px;
		}

		.btn-default {
			width: 100%;
			max-width: 330px;
			border-radius: 0;
			color: var(--color-secondary);
			text-transform: uppercase;
			letter-spacing: 2px;
			font-size: 14px;
			padding: 9px 30px;

			&:hover {
				color: #fff;
			}
		}
	}

}