.search-page-layout-v3 {
	background-color: var(--color-white);
	margin-top: var(--page-margin-top);
	border-radius: 0px;
	background: url(RESOURCE/img/search-banner.jpg) no-repeat;
	background-color: #0f0f17 !important;
	background-size: 100%;
	background-attachment: fixed;
	margin: 0px;
	padding: 140px 0px !important;

	@media (max-width: 992px) {
		padding: 95px 0 60px 0 !important;
	}

	@media (max-width: 767px) {
		background-color: #0f0f17 !important;
		background-size: contain;
		margin: 0px;
		background-position: 0 100px;
		padding: 80px 0px !important;
		background-attachment: scroll;
	}

	h1.heading1 {
		font-family: Avenir LT Pro\ 65;
		font-size: 32px;
		color: #445283;
		font-weight: 500;
		line-height: 1.2;
		margin: 0 0 20px;
		letter-spacing: 4px;
		text-transform: uppercase;
		text-align: center;
		color: #fff;
		margin: 130px 0px 90px 0px;
		width: 100%;

		@media (max-width: 767px) {
			font-size: 1.8rem !important;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			padding-left: 100px;
			padding-right: 100px;
		}
	}

	.house-selector {
		/*background: rgba(11, 10, 10, 0.75);*/
		background: #ffffff;
		position: fixed;
		top: 0;
		z-index: 9;
		width: 100%;
		margin-top: 95px;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 992px) {
			margin-top: 120px;
		}

		@media (max-width: 992px) {
			margin-top: 95px;
		}

		ul {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;

			li {

				&:hover {
					img {
						transform: scale(1.05);
					}
				}

				a.active {
					img {
						filter: brightness(255) invert(255);
					}
				}

				img {
					max-width: 100px;
					margin: 5px 5px 0px 5px;
					transition: all 0.4s ease-in-out;


				}
			}
		}

		.paymentmethods {
			font-size: 40px;
			margin-left: 20px;
			color: #bda27c;

			@media (max-width: 767px) {
				display: none;
			}

			i {
				font-weight: 400;
			}
		}
	}

	.list-view {
		.btn.btn-default {
			background: var(--color-secondary);
			border-radius: 0;
			border: 1px solid var(--color-secondary);
			color: #fff;

			&:hover {
				background: transparent;
				color: var(--color-secondary);
			}
		}
	}

	.results {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;

		@media (min-width:1200px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		/*	@media (max-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		} */

		@media (max-width:992px) {
			grid-template-columns: 1fr;
		}

	}

	.more-btn-panel {
		margin-top: 30px;

		.btn-secondary {
			font-size: .7em !important;
			padding: 9px 15px !important;
			border: 1px solid #fff;
			color: #fff;

			&:hover {
				background: #fff;
				color: var(--color-secondary);
			}
		}
	}

	.container,
	.container-xl {
		@media (min-width:1200px) {
			/*max-width: 1370px; */
		}

		@media (min-width:1500px) {
			max-width: 1370px;
		}
	}

}

.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 1px;
		padding-bottom: 2px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}