.unit-pricelist-v1 {
	background-color: rgba(0, 0, 0, 0.45) !important;
	padding: 20px;

	.table-responsive {
		display: block;
		width: 100%;
		overflow-x: visible;
		-webkit-overflow-scrolling: touch;
	}


	h3 {
		display: inline-block;
		padding-right: 0;
		padding-left: 0;
		border-bottom: 0 solid var(--heading1-border-color);
		text-transform: var(--heading1-text-transform);
		padding-bottom: 0;
		font-size: 20px;
		text-transform: uppercase;
		letter-spacing: .1em;
		color: var(--color-white);
		margin: 0 0 20px;
		font-weight: 600;
	}


	.table-hover tbody tr:hover {
		color: #fff !important;
	}

	.table tbody+tbody {
		border-top: 2px solid rgb(222 226 230 / 55%);
	}

	.table td,
	.table th {
		border-top: 1px solid rgb(222 226 230 / 20%)
	}

	.pricelist {
		color: #fff;
		margin: 0;

		.id41085 {
			display: none !important;
		}

		.id43080 {
			display: none !important;
		}

		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}
}