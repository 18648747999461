.regionen-v1 {
	margin-top: var(--page-margin-top);

	.img-thumbnail {
		padding: 0;
		border-radius: 0;
		width: 100%;
		border: none;
	}

	.section-space {
		padding: 80px 0px 60px 0px;

		@media (max-width: 767px) {
			padding: 60px 0 40px 0;
		}
	}

	.floattext-icon {
		margin: 20px 0px 30px 0px;
		max-width: 160px;
	}

	h5 {
		font-family: Avenir LT Pro\ 65;
		color: #646464;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 20px;
		letter-spacing: 1px;
	}

	h6 {
		font-size: 15px;
	}

	h3 {
		font-size: 25px;
		color: var(--color-secondary);
		margin: 0 0 30px;
		position: relative;

		&:before {
			content: "\00bb";
			font-family: Font Awesome\ 5 Free;
		}

		&:after {
			content: "\00ab";
			font-family: Font Awesome\ 5 Free;
		}
	}

	.min-container {
		max-width: 950px;
	}

	.top-80 {
		img {
			object-position: 0 -80px !important;

			@media (max-width: 1199px) {
				object-position: top !important;
			}
		}
	}
}