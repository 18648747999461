.unit-page-layout-v1 {
	background-color: var(--color-white);
	margin-top: var(--page-margin-top);
	border-radius: 0px;
	background: url(RESOURCE/img/search-banner.jpg) no-repeat;
	background-color: #0f0f17 !important;
	background-size: 100%;
	background-attachment: fixed;
	margin: 0px;
	padding: 95px 0px !important;

	@media (max-width: 767px) {
		padding-bottom: 60px !important;
	}

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}

	.matterport {

		iframe {
			height: 525px !important;

			@media (max-width: 767px) {
				height: 300px !important;
			}
		}

		h3 {
			display: inline-block;
			padding-right: 0;
			padding-left: 0;
			border-bottom: 0 solid var(--heading1-border-color);
			text-transform: var(--heading1-text-transform);
			padding-bottom: 0;
			font-size: 20px;
			text-transform: uppercase;
			letter-spacing: .1em;
			color: var(--color-white);
			margin: 0 0 20px;
			font-weight: 600;
		}

	}

	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: rgba(0, 0, 0, .45) !important;
			padding: 20px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 120px;
			display: none;
			margin-bottom: 57px;
			margin-top: 30px !important;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}

		.btn-default {
			border-radius: 0;
			color: var(--color-secondary);
			text-transform: uppercase;
			letter-spacing: 2px;
			font-size: 14px;
			padding: 9px 30px;

			&:hover {
				color: #fff;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

	.unit-book-now-v4 {
		.btn.btn-default {
			border-radius: 0;
			color: var(--color-secondary);
			text-transform: uppercase;
			letter-spacing: 2px;
			font-size: 14px;
			padding: 9px 30px;

			&:hover {
				color: #fff;
			}
		}
	}

	.unit-map-v1 {
		background-color: rgba(0, 0, 0, .45) !important;
		padding: 20px;

		h3 {
			display: inline-block;
			padding-right: 0;
			padding-left: 0;
			border-bottom: 0 solid var(--heading1-border-color);
			text-transform: var(--heading1-text-transform);
			padding-bottom: 0;
			font-size: 20px;
			text-transform: uppercase;
			letter-spacing: .1em;
			color: var(--color-white);
			margin: 0 0 20px;
			font-weight: 600;
		}
	}

	.unit-tabs {
		background: #fff;
		margin: 50px 0 60px 0;

		ul.nav-tabs {
			li {

				@media (max-width: 992px) {
					width: 33.33%;
				}

				@media (max-width: 767px) {
					width: 50%;
				}

				@media all and (min-width: 1201px) and (max-width: 1499px) {
					width: 33.33%;
				}


				&:last-child {
					a {
						border-right: none !important;
					}
				}

				a {
					font-family: Avenir LT Pro\ 65;
					color: #0a0a0a;
					text-transform: uppercase;
					font-size: 13px;
					line-height: 20px;
					letter-spacing: 1px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 15px 18.91px;
					text-decoration: none;
					border-right: 1px solid var(--color-secondary);


					@media (max-width: 992px) {
						border-bottom: 1px solid var(--color-secondary);
					}

					@media all and (min-width: 1201px) and (max-width: 1499px) {
						border-bottom: 1px solid var(--color-secondary);
					}

					&.active {
						background: var(--color-secondary) !important;
						color: #fff;
					}
				}

			}
		}

		.tick-li {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;

			@media (max-width:992px) {
				font-size: var(--font-size-md);
			}

			li {
				width: 33%;
				padding-left: 30px;
				padding-right: 15px;
				min-height: 30px;
				position: relative;
				font-size: 16px;

				@media (max-width:992px) {
					width: 50%;
				}

				@media (max-width:480px) {
					width: 100%;
				}

				&:before {
					content: "\f00c";
					font-family: 'Font Awesome 5 Pro';
					-webkit-font-smoothing: antialiased;
					font-weight: 300;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--color-primary);
				}
			}
		}

		.tab-pane {
			padding: 30px;

			h3 {
				display: inline-block;
				padding-right: 0;
				padding-left: 0;
				border-bottom: 0 solid #445283;
				border-bottom: 0 solid var(--heading1-border-color);
				text-transform: var(--heading1-text-transform);
				padding-bottom: 0;
				font-size: 20px;
				text-transform: uppercase;
				letter-spacing: .1em;
				color: var(--color-secondary);
				margin: 0 0 20px;
				font-weight: 600;
			}
		}

		.widget-block {
			border: none;
			padding: 0;
		}
	}

}