.footer-v1 {
	padding: 80px 0px 60px 0px !important;
	background: #fbfbfb;

	.footer-logo {
		max-width: 150px;
		margin: 0px 0px 30px 0px;
	}

	h2 {
		font-size: 2.1em;
		font-family: 'Avenir LT Pro 65';
		margin: 0 0 20px;
		color: #445283;
		font-weight: 500;
		line-height: 1.2;
		margin-bottom: 5px;
		text-transform: uppercase;
		letter-spacing: 0.1em;

		@media (max-width: 767px) {
			font-size: 1.45rem !important;
		}
	}

	h5 {
		font-size: 0.75em;
		line-height: 160%;
		font-family: 'Avenir LT Pro 65';
		text-transform: uppercase;
		letter-spacing: 0.1em;
		color: rgba(100, 100, 100, 1.0);
		margin-bottom: 20px;
		text-transform: uppercase;
		letter-spacing: 0.1em;

		@media (max-width: 767px) {
			font-size: 13px;
			letter-spacing: 0.8px;
		}
	}

	.address {
		margin-bottom: 0;

		&.phone {
			li {
				&:last-child {
					border-left: 1px solid #646464;
				}
			}
		}

		li {
			display: inline;
			padding: 10px 15px;
			font-size: 18px;
			letter-spacing: 0.03em;
			color: rgba(100, 100, 100, 1.0);

			@media (max-width: 992px) {
				display: block;
				margin: 10px 15px;
				font-size: 16px;
				letter-spacing: normal;
			}



			a {
				color: rgba(100, 100, 100, 1.0);
				position: relative;
			}

		}
	}

	.icons {
		list-style: none;
		margin: 40px 0px;
		padding: 0px;

		li {
			display: inline;
			margin: 0px 15px;

			@media (max-width: 992px) {
				margin: 0 10px;
			}

			img {
				max-width: 45px;
				transition: all 0.4s ease-in-out;

				&:hover {
					transform: scale(1.15);
				}
			}
		}
	}

	.footermenu {
		list-style: none;
		margin: 20px 0px;
		padding: 0px;

		li {
			display: inline;
			margin: 0px 12px;

			@media (max-width: 992px) {
				display: block;
				margin: 10px 0;
				width: 100%;
			}

			a {
				font-size: 0.65em;
				font-family: 'Avenir LT Pro 65';
				text-transform: uppercase;
				letter-spacing: 2px;
				color: rgba(10, 10, 10, 1.0);
				transition: color 0.25s;
				padding: 6px 0px 4px 0px;
				text-decoration: none;

				&:hover {
					color: var(--color-secondary);
				}
			}
		}
	}



	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: -70px;
			padding-right: 15px;

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

	.payment-icon {
		margin-top: 20px;
		color: #646464;
		font-size: 16px;

		lable {
			font-weight: 700;
		}

		span {
			margin: 0 12px;
		}
	}
}