.unit-booking-v1 {
	background-color: rgba(0, 0, 0, .45) !important;
	padding: 20px;

	h3 {
		display: inline-block;
		padding-right: 0;
		padding-left: 0;
		border-bottom: 0 solid var(--heading1-border-color);
		text-transform: var(--heading1-text-transform);
		padding-bottom: 0;
		font-size: 20px;
		text-transform: uppercase;
		letter-spacing: .1em;
		color: var(--color-white);
		margin: 0 0 20px;
		font-weight: 600;
	}

	.search-bl {
		position: relative;

		.fa {
			position: absolute;
			top: 15px;
			color: var(--color-grey-dark);
			right: 10px;
		}
	}

	.form-control {
		border: none;
		border-bottom: 1px solid #e6e6e6;
		text-align: left;
		margin-bottom: 15px;
		padding: 10px 0;
		background-color: var(--color-white);
		font-size: var(--font-size-md);
		height: auto;
		min-height: 45px;
		padding: 10px 10px;
		padding-right: 30px;
		border-radius: 0 !important;
	}

	.btn.btn-default {
		border-radius: 0;
		color: var(--color-secondary);
		text-transform: uppercase;
		letter-spacing: 2px;
		font-size: 14px;
		padding: 9px 10px;

		&:hover {
			color: #fff;
		}
	}

	.table {
		td {
			border-top: 2px solid var(--color-white);
		}

		tbody {
			border-top: 2px solid var(--color-white);
		}
	}

	.optionals {
		margin-top: 20px;
	}

	.optionals-title {
		font-size: var(--h4-font-size);
		font-weight: var(--h4-font-weight);
		padding-left: 10px;
		color: #fff;
	}

	.consumptioncosts-title {
		font-size: var(--h5-font-size);
		font-weight: var(--h5-font-weight);
		padding-left: 10px;
	}

	.button-row {
		text-align: right;
		margin-top: 20px;

	}

	.subrow {
		margin-top: 20px;
	}

	.btn-option {
		margin-top: 10px;
	}
}