.coupon-v1 {

	padding: 20px 20px;
	border: 1px solid #fff;
	background-color: transparent;

	.h3,
	h3 {
		color: #fff !important;
	}

	.coupon-field {
		margin-top: 20px;
	}

	.form-control {
		border-radius: 0 !important;
	}

	.btn.btn-success {
		border-radius: 0;
		color: #fff !important;
		background: var(--color-secondary);
		border-color: var(--color-secondary);
	}
}