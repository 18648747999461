.hero-image-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);
	overflow: hidden;

	@media (max-height: 600px) {
		min-height: 680px;

	}

	@media (max-width: 767px) {
		min-height: unset;
		height: auto !important;
		margin: 95px 0 0;
	}

	.btn-primary {
		background: rgba(168, 131, 81, 0.8);
		text-transform: uppercase;
		border: 1px solid var(--white);
		letter-spacing: 2px;
		font-size: 16px;
		padding: 10px 30px 9px 30px;
		margin: 20px 0px;
		transition: background 0.25s;
		color: #fff;

		&:hover {
			background: rgba(168, 131, 81, 1.0) !important;
			border: 1px solid var(--white);
		}

		@media (max-width: 767px) {
			font-size: 14px;
		}
	}

	video {
		visibility: visible;
		opacity: 1;
		width: 100%;
		height: auto;

		@media (max-width: 1220px) {
			width: auto !important;
			height: 102% !important;
		}

		@media (max-width: 767px) {
			height: auto !important;
			overflow: hidden;
			width: 100% !important;
		}

	}

	.picture {
		height: var(--index-slide-show-height);
		min-height: var(--index-slide-show-height);

		@media (max-height: 600px) {
			min-height: 680px
		}

		@media (max-width: 767px) {
			height: auto;
			min-height: unset;
		}

		.img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.hero-header {
		display: none;
		position: absolute;
		top: 35vh;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media (max-width: 992px) {
			top: 35vh;
		}

		@media (max-width: 767px) {
			top: unset;
			position: relative;
			display: block !important;
		}

		@media (max-height: 600px) {
			top: unset;
			position: relative;
		}

		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
			}

			h1 {
				text-transform: uppercase;
				letter-spacing: 6px;
				font-size: 44px;
				line-height: 64px;
				text-shadow: rgba(0, 0, 0, 0.5) 2px 2px 10px;
				color: var(--white);
				font-family: "Avenir LT Pro 65";
				max-width: 600px;
				font-weight: 500;
				margin: 0px auto;


				@media (min-width: 1600px) {
					font-size: 44px;
					line-height: 64px;
				}

				@media (max-width: 1140px) {
					font-size: 48px;
				}

				@media (max-width: 992px) {
					font-size: 2rem;
					line-height: 3rem;
				}

				@media (max-width: 767px) {
					text-transform: uppercase;
					letter-spacing: 6px;
					font-size: 1.2em;
					line-height: 1.4em;
					text-shadow: none;
					color: #131313;
					max-width: 320px;
				}

				@media (max-height: 600px) {
					font-size: 1em;
					line-height: 1.4em;
				}

				@media all and (min-width: 768px) and (max-width: 992px) {
					font-size: 38px;
					max-width: 500px;
					line-height: normal;
				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.3rem;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .7);

				@media (min-width: 1600px) {
					font-size: 2.3rem;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 1.7rem;
				}

				@media (max-width: 575px) {
					font-size: 1.2rem;
				}
			}
		}

	}
}